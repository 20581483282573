import { PropsWithChildren } from "react";
import { clsx } from "clsx";

type Props = {
  disableGrid?: boolean;
};

export function Page({
  children,
  disableGrid = false,
}: PropsWithChildren<Props>) {
  return (
    <main
      className={clsx("mx-auto", "w-[92.5%]", {
        "grid grid-cols-12 gap-[30px]": !disableGrid,
      })}
    >
      {children}
    </main>
  );
}
